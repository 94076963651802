// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preorder-type-chip {
  height: 16px;
  padding-inline: 10px;
  background: var(--ion-color-primary);
  border: 1px solid var(--preorder-type-chip-border-color);
  border-radius: 28px;
  font-family: AppFontBold;
  font-size: 10px;
  line-height: 1px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/preorder-type-chip/preorder-type-chip.component.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,oBAAA;EACA,oCAAA;EACA,wDAAA;EACA,mBAAA;EACA,wBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACD","sourcesContent":[".preorder-type-chip {\n\theight: 16px;\n\tpadding-inline: 10px;\n\tbackground: var(--ion-color-primary);\n\tborder: 1px solid var(--preorder-type-chip-border-color);\n\tborder-radius: 28px;\n\tfont-family: AppFontBold;\n\tfont-size: 10px;\n\tline-height: 1px;\n\tletter-spacing: 0em;\n\ttext-align: center;\n\tcolor: white;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
