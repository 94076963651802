import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import Order from 'src/smoothr-web-app-core/models/Order';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import { ModalController } from '@ionic/angular';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';

@Component({
	selector: 'app-order-page-toolbar',
	templateUrl: './order-page-toolbar.component.html',
	styleUrls: ['order-page-toolbar.component.scss'],
})
export class OrderPageToolbarComponent implements OnInit {
	@Output()
	backClick = new EventEmitter<void>();
	@Output()
	close = new EventEmitter<void>();
	@Input()
	preorderType: PreorderType;
	@Input()
	title: string;
	@Input()
	hideElements: boolean;
	@Input()
	venue: Venue;
	@Input()
	order: Order;
	environment = environment;

	pt = PreorderType;

	constructor(
		private modalCtrl: ModalController,
		protected repository: RepositoryService
	) {}

	ngOnInit() {
		console.log(this.order);
	}

	onBackClick() {
		this.backClick.emit();
	}
	onClose() {
		this.close.emit();
	}

	// async openSelectTableModal(openByToolbar?: boolean) {
	// 	if (this.venue && this.order.preorder.type === PreorderType.INSIDE) {
	// 		if (this.order.tableNumber === 'preorder_table' || openByToolbar) {
	// 			const selectTable = await SelectTableModalComponent.show(this.modalCtrl, this.venue, this.order);
	// 			if (selectTable) {
	// 				this.order.tableNumber = selectTable.number;
	// 				this.order.table = selectTable._id;
	// 				this.repository.onOrderChange(this.order);
	// 				await SelectTableInfoModalComponent.show(this.modalCtrl, this.order.tableNumber);
	// 			}
	// 		}
	// 	}
	// }
}
