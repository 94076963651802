import { Component, EventEmitter, Input, Output } from '@angular/core';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import { WorkingHoursModalComponent } from '../working-hours-modal/working-hours-modal.component';
import { ModalController } from '@ionic/angular';
import { PreorderType, PreorderTypeArray } from '../../../smoothr-web-app-core/enums/PreorderType';
import moment, { Moment } from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { getSlots, getSlotsByOrder, isVenueOpen, venueAcceptsOrders } from '../../../smoothr-web-app-core/utils/utils';
import { TimeUtils } from 'src/smoothr-web-app-core/utils/time-utils';

@Component({
	selector: 'app-venue-suggestion',
	templateUrl: './venue-suggestion.component.html',
	styleUrls: ['venue-suggestion.component.scss'],
})
export class VenueSuggestionComponent {
	environment = environment;
	@Input() type: PreorderType;
	@Output() venuePick = new EventEmitter<{ venue: Venue; type: PreorderType }>();
	isVenueOpen = false;
	pt = PreorderType;
	@Input()
	isMap = false;
	@Input()
	overridePostalDelivery = false;
	acceptsDelivery = false;
	firstDeliveryTime: string = null;
	acceptsInside = false;
	firstInsideTime: string = null;
	acceptsTakeaway = false;
	firstTakeAwayTime: string = null;
	@Input()
	selected = false;
	ptArray = PreorderTypeArray.filter(it => it !== PreorderType.PARK_COLLECT && it !== PreorderType.DELIVERY);
	openSelectionType = true;
	externalDelivery = ['lieferando', 'wolt', 'uber_eats'];

	constructor(
		private modalCtrl: ModalController,
		private translate: TranslateService
	) {}

	private _venue: Venue;

	get venue(): Venue {
		return this._venue;
	}

	@Input()
	set venue(venue: Venue) {
		this._venue = venue;
		this.acceptsDelivery = false;
		this.acceptsTakeaway = false;
		this.acceptsInside = false;
		this.firstDeliveryTime = null;
		this.firstTakeAwayTime = null;
		this.firstInsideTime = null;
		this.isVenueOpen = false;
		if (venue === undefined || venue === null) {
			return;
		}
		this.isVenueOpen = isVenueOpen(venue);
		const isOpenResturant = TimeUtils.doesHoursMatchNow(venue.openingHours);
		const isDeliveryEnabled = this.venue.openingHours.length > 0 && TimeUtils.doesHoursMatchNow(this.venue.deliveryHours);

		// this.acceptsTakeaway = isOpenResturant && this.venue.preorderTakeAway && this.venue.isServiceActivated && this.venue.isPublished;
		//TODO: for testing
		this.acceptsTakeaway = true;
		this.acceptsInside = isOpenResturant && this.venue.preorderIn && this.venue.isServiceActivated && this.venue.isPublished;
		this.acceptsDelivery = isDeliveryEnabled && this.venue.deliveryEnabled && this.venue.isServiceActivated && this.venue.isPublished;
	}

	selectVenue(venue: Venue, type: PreorderType) {
		console.log('SELECT VENUE');
		if (!this.accepts(type)) {
			return;
		}
		this.venuePick.emit({ venue, type });
	}

	accepts(type: PreorderType): boolean {
		switch (type) {
			case PreorderType.DELIVERY:
				return this.acceptsDelivery;
			case PreorderType.TAKE_AWAY:
				return this.acceptsTakeaway;
			case PreorderType.INSIDE:
				return this.acceptsInside;
			default:
				return false;
		}
	}

	openModal(venue: Venue) {
		WorkingHoursModalComponent.show(this.modalCtrl, venue, this.type);
	}

	timeToText(venue: Venue, time: Moment | null) {
		if (time && venue.slot) {
			const now = moment().add(
				Math.abs((moment().minutes() % venue.slot.interval) - venue.slot.interval) % venue.slot.interval,
				'minutes'
			);
			const roundedTime = time.add(
				Math.abs((time.minutes() % venue.slot.interval) - venue.slot.interval) % venue.slot.interval,
				'minutes'
			);
			const relevantTime = now.isAfter(roundedTime) ? now : roundedTime;
			const isToday = now.dayOfYear() === relevantTime.dayOfYear() && now.year() === relevantTime.year();
			return (isToday ? '' : this.translate.instant('venue_suggestion.tomorrow') + ' ') + relevantTime.format('HH:mm');
		} else {
			return null;
		}
	}
	isVenueOpenFunc() {
		return this.isVenueOpen ? this.translate.instant('venue_suggestion.open') : this.translate.instant('venue_suggestion.close');
	}
	checkIfExist(item: string) {
		return ((this.venue.externalDeliveryService as any[]) ?? []).find(it => it?.name === item)?.link;
	}
	navigateTo(link: string) {
		if (!link) {
			return;
		}
		window.open(link, '_blank');
	}
	navigateToMap(venue: Venue) {
		if (!venue) {
			return;
		}
		window.open(
			'https://maps.google.de/maps?q=' + venue.street + ' ' + venue.number + ', ' + venue.postalCode + ' ' + venue.city.de,
			'_blank'
		);
	}
}
