import { apiEnvironment } from '../smoothr-web-app-core/environments/apiEnvironment';

export const theCupCustomerGroup = 'the_cup';
export const customerGroup = theCupCustomerGroup;

const supportEmail = {
	the_cup: 'the_cup@smoothr.de',
};
const firebaseConfig = {
	the_cup: {
		apiKey: 'AIzaSyD_F0TJ0KmjEBzOU8lazhZ7mQepVpcUc2E',
		authDomain: 'onni-korean-delights.firebaseapp.com',
		projectId: 'onni-korean-delights',
		storageBucket: 'onni-korean-delights.appspot.com',
		messagingSenderId: '987655246744',
		appId: '1:987655246744:web:e25c964bdc856f0c3a91ed',
	},
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyDAdrZ5vG1_A-AIzaSyCdWENWO1yRQTwMLryya4sBTu_M6ZPvM20',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment,
	countryList: ['de'],
	gaId: '',
};
