// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slot-square {
  background: white;
  border: 1px solid rgba(34, 34, 34, 0.1019607843);
  color: var(--ion-color-black);
  text-align: center;
  padding: var(--ion-margin);
  transition: border-top-color 0.2s linear, border-bottom-color 0.2s linear, border-left-color 0.2s linear, border-right-color 0.2s linear, color 0.2s linear, background-color 0.2s linear;
  text-transform: uppercase;
  margin-bottom: 4px;
  cursor: pointer;
  border-radius: 0px;
  font-family: AppFontBold, san-serif;
  font-size: 14px;
}
.slot-square.selected {
  border: 2px solid var(--ion-color-secondary);
}

.slot-col {
  max-height: 250px;
  overflow: auto;
}

.title {
  color: #222222;
  line-height: 17px;
  font-size: 20px;
  font-family: AppFontBold, san-serif;
  text-align: center;
  margin-bottom: 5px;
}

.no-padding {
  padding: 0px !important;
}

.smaller {
  font-size: 13px;
  padding: 13px 0px;
  text-transform: lowercase;
}`, "",{"version":3,"sources":["webpack://./src/app/components/select-date-formcontrol/select-date-formcontrol.component.scss"],"names":[],"mappings":"AAEA;EACC,iBAAA;EACA,gDAAA;EACA,6BAAA;EACA,kBAAA;EACA,0BAAA;EACA,yLAAA;EAEA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,mCAAA;EACA,eAAA;AAFD;AAGC;EACC,4CAAA;AADF;;AAIA;EACC,iBAAA;EACA,cAAA;AADD;;AAIA;EACC,cAAA;EACA,iBAAA;EACA,eAAA;EACA,mCAAA;EACA,kBAAA;EACC,kBAAA;AADF;;AAGA;EACC,uBAAA;AAAD;;AAEA;EACC,eAAA;EACG,iBAAA;EACA,yBAAA;AACJ","sourcesContent":["@import '../../../theme/mixins';\n\n.slot-square {\n\tbackground: white;\n\tborder: 1px solid #2222221a;\n\tcolor: var(--ion-color-black);\n\ttext-align: center;\n\tpadding: var(--ion-margin);\n\ttransition: border-top-color 0.2s linear, border-bottom-color 0.2s linear, border-left-color 0.2s linear, border-right-color 0.2s linear,\n\t\tcolor 0.2s linear, background-color 0.2s linear;\n\ttext-transform: uppercase;\n\tmargin-bottom: 4px;\n\tcursor: pointer;\n\tborder-radius: 0px;\n\tfont-family: AppFontBold, san-serif;\n\tfont-size: 14px;\n\t&.selected {\n\t\tborder: 2px solid var(--ion-color-secondary);\n\t}\n}\n.slot-col {\n\tmax-height: 250px;\n\toverflow: auto;\n}\n\n.title {\n\tcolor: #222222;\n\tline-height: 17px;\n\tfont-size: 20px;\n\tfont-family: AppFontBold, san-serif;\n\ttext-align: center;\n  margin-bottom: 5px;\n}\n.no-padding {\n\tpadding: 0px !important;\n}\n.smaller {\n\tfont-size: 13px;\n    padding: 13px 0px;\n    text-transform: lowercase;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
