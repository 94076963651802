import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Hours from '../../../smoothr-web-app-core/models/Hours';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { AppComponent } from '../../app.component';
import { TranslateService } from '@ngx-translate/core';
import { TimeUtils } from '../../../smoothr-web-app-core/utils/time-utils';
import { environment } from 'src/environments/environment';
import { Api } from '../../../smoothr-web-app-core/api/api';
import { ShortInfoModalComponent } from '../short-info-modal/short-info-modal.component';
import { numberToCurrency } from 'src/smoothr-web-app-core/utils/utils';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
	selector: 'app-working-hours-modal',
	templateUrl: './working-hours-modal.component.html',
	styleUrls: ['working-hours-modal.component.scss'],
})
export class WorkingHoursModalComponent {
	venue: Venue;
	type: PreorderType;
	pt = PreorderType;
	environment = environment;
	numberToCurrency = numberToCurrency;
	// tslint:disable-next-line:variable-name
	private _longDays = [
		this.translate.instant('weekday.monday'),
		this.translate.instant('weekday.tuesday'),
		this.translate.instant('weekday.wednesday'),
		this.translate.instant('weekday.thursday'),
		this.translate.instant('weekday.friday'),
		this.translate.instant('weekday.saturday'),
		this.translate.instant('weekday.sunday'),
	];

	constructor(
		private modalCtrl: ModalController,
		private translate: TranslateService,
		private navService: NavigationService
	) {}

	// tslint:disable-next-line:variable-name
	private _days = [
		this.translate.instant('weekday.monday_short'),
		this.translate.instant('weekday.tuesday_short'),
		this.translate.instant('weekday.wednesday_short'),
		this.translate.instant('weekday.thursday_short'),
		this.translate.instant('weekday.friday_short'),
		this.translate.instant('weekday.saturday_short'),
		this.translate.instant('weekday.sunday_short'),
	];

	get days() {
		// return AppComponent.largeScreen ? this._longDays : this._days;
		return this._longDays;
	}

	static async show(modalCtrl: ModalController, venue: Venue, type: PreorderType) {
		try {
			venue.legal = (await Api.getLegal(venue._id)).data;
			console.log({
				venue: venue.name,
				legal: venue.legal,
				id: venue._id,
			});
		} catch (e) {
			console.error('Error while getting legal information');
			console.error(e);
		}
		const modal = await modalCtrl.create({
			component: WorkingHoursModalComponent,
			cssClass: 'working-hours-modal',
			componentProps: {
				venue,
				type,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
	}

	async openHtml(html: string) {
		await ShortInfoModalComponent.show(this.modalCtrl, {
			de: html,
		});
	}

	dayText(schedule: Hours[], index: number): string[] {
		const res = [];

		const allConnected = schedule.map(hour => hour.connectedWithPrev).indexOf(false) < 0;
		for (const hour of schedule) {
			if (hour.connectedWithPrev && !allConnected) {
				continue;
			}
			if (hour.weekday === index) {
				const hours = TimeUtils.hoursToSchedule(hour);
				res.push(
					hours.openedAt.format('HH:mm') + ' - ' + hours.closedAt.format('HH:mm')
					// +
					// ` ${this.translate.instant('working_hours_modal.hours')}`
				);
			}
		}

		if (res.length !== 0) {
			return res;
		}
		return [this.translate.instant('venue_suggestion.close')];
	}

	mappedDays(days: Hours[]): [string, string][] {
		const newResp: {
			[key: string]: string[];
		} = {};
		const transformedNewResp: {
			[key: string]: string;
		} = {};
		const closedKey = this.translate.instant('venue_suggestion.close');

		console.log(this.days);

		for (let i = 0; i < this.days.length; i++) {
			if (days.find(d => d.weekday === i)) {
				const from = days[i].openedAt;
				const to = days[i].closedAt;
				const key = `${from} - ${to}`;
				if (key in newResp) {
					newResp[key].push(this.days[i]);
				} else {
					newResp[key] = [this.days[i]];
				}
			} else {
				if (closedKey in newResp) {
					newResp[closedKey].push(this.days[i]);
				} else {
					newResp[closedKey] = [this.days[i]];
				}
			}
		}

		for (let key in newResp) {
			transformedNewResp[key] = this.transformDaysOfWeek(newResp[key]);
		}

		return Object.entries(transformedNewResp);
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}

	transformDaysOfWeek(days: string[]) {
		if (days.length === 2) {
			return days.join(' & ');
		} else {
			let lastDay = days.pop();
			return days.join(', ') + ' & ' + lastDay;
		}
	}

	async toPrivacy() {
		await this.modalCtrl.dismiss();
		await this.navService.privacy();
	}
	async toAGB() {
		await this.modalCtrl.dismiss();
		await this.navService.tos();
	}
	async toImpressum() {
		await this.modalCtrl.dismiss();
		await this.navService.imprint();
	}
}
