import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Localization from 'src/smoothr-web-app-core/models/Localization';
import Venue from 'src/smoothr-web-app-core/models/Venue';

@Component({
	selector: 'app-restaurant-selection-modal',
	templateUrl: './restaurant-selection-modal.component.html',
	styleUrls: ['./restaurant-selection-modal.component.scss'],
})
export class RestaurantSelectionModalComponent {
	venue: Venue;

	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController, venue: Venue): Promise<boolean> {
		const modal = await modalCtrl.create({
			component: RestaurantSelectionModalComponent,
			cssClass: 'restaurant-info-modal',
			componentProps: {
				venue,
			},
			backdropDismiss: false,
		});
		await modal.present();
		const response = await modal.onDidDismiss();

		return response.data;
	}

	close(res: boolean) {
		this.modalCtrl.dismiss(res);
	}
}
