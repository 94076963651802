// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 26px 21px;
  text-align: center;
}
.container ion-button {
  margin: 36px 0px 0;
  width: 100%;
}
.container h3 {
  font-family: AppFontBold, sans-serif;
  font-size: 22px;
  line-height: 22px;
  text-transform: uppercase;
}
.container p {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  margin: 6px 0;
  font-family: AppFontMedium, sans-serif;
  color: #222222;
}

ion-img {
  height: 61px;
  width: 42px;
  margin-bottom: 26px;
}

ion-button {
  text-transform: uppercase;
  --border-radius: none;
}

.input-error {
  --border-color: red;
}

.table {
  width: 100%;
}

ion-footer {
  background: transparent;
  box-shadow: none;
  padding: 10px;
}
ion-footer ion-button {
  text-transform: uppercase;
  width: 100%;
}

.header-name {
  display: flex;
  align-items: center;
}
.header-name h4 {
  margin-left: 10px;
  font-family: AppFont, sans-serif;
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/select-table-info-modal/select-table-info-modal.component.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EAOA,kBAAA;AALD;AAAC;EACC,kBAAA;EACA,WAAA;AAEF;AAGC;EACC,oCAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;AADF;AAIC;EACC,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,aAAA;EACA,sCAAA;EACM,cAAA;AAFR;;AAMA;EACC,YAAA;EACA,WAAA;EACG,mBAAA;AAHJ;;AAKA;EACC,yBAAA;EACA,qBAAA;AAFD;;AAKA;EACC,mBAAA;AAFD;;AAIA;EACC,WAAA;AADD;;AAIA;EACC,uBAAA;EACA,gBAAA;EACA,aAAA;AADD;AAEC;EACC,yBAAA;EACA,WAAA;AAAF;;AAGA;EACC,aAAA;EACA,mBAAA;AAAD;AACC;EACC,iBAAA;EACA,gCAAA;EACA,eAAA;AACF","sourcesContent":[".container {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tpadding: 26px 21px;\n\n\tion-button {\n\t\tmargin: 36px 0px 0;\n\t\twidth: 100%;\n\t}\n\n\ttext-align: center;\n\n\th3 {\n\t\tfont-family: AppFontBold, sans-serif;\n\t\tfont-size: 22px;\n\t\tline-height: 22px;\n\t\ttext-transform: uppercase;\n\t}\n\n\tp {\n\t\tfont-size: 14px;\n\t\tline-height: 21px;\n\t\tletter-spacing: 0.01em;\n\t\tmargin: 6px 0;\n\t\tfont-family: AppFontMedium, sans-serif;\n        color: #222222;\n\t}\n}\n\nion-img {\n\theight: 61px;\n\twidth: 42px;\n    margin-bottom: 26px;\n}\nion-button {\n\ttext-transform: uppercase;\n\t--border-radius: none;\n}\n\n.input-error {\n\t--border-color: red;\n}\n.table {\n\twidth: 100%;\n}\n\nion-footer {\n\tbackground: transparent;\n\tbox-shadow: none;\n\tpadding: 10px;\n\tion-button {\n\t\ttext-transform: uppercase;\n\t\twidth: 100%;\n\t}\n}\n.header-name {\n\tdisplay: flex;\n\talign-items: center;\n\th4 {\n\t\tmargin-left: 10px;\n\t\tfont-family: AppFont, sans-serif;\n\t\tfont-size: 16px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
