import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { environment } from '../../../environments/environment';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { isVenueOpen, sleep, venueAcceptsOrders } from '../../../smoothr-web-app-core/utils/utils';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Address from '../../../smoothr-web-app-core/models/Address';
import { ModalController } from '@ionic/angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Api } from 'src/smoothr-web-app-core/api/api';
import { BehaviorSubject } from 'rxjs';
import { TimeUtils } from 'src/smoothr-web-app-core/utils/time-utils';
import { WorkingHoursModalComponent } from 'src/app/components/working-hours-modal/working-hours-modal.component';
import { MenuPage } from '../menu/menu.page';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';
import { NavigationService } from 'src/app/services/navigation.service';

enum Language {
	GreatBritain = 'en',
	German = 'de',
}

@Component({
	selector: 'app-select-venue',
	templateUrl: './select-venue.page.tabilo.html',
	styleUrls: ['./select-venue.page.tabilo.scss'],
})
export class SelectVenuePage extends RepositoryDirective implements OnInit {
	static url = 'select-venue';
	// static scanQr = 'scan-qr/:venueId/:tableId';
	environment = environment;
	languageEnum = Language;

	loading = false;
	preorderTypes = [PreorderType.INSIDE, PreorderType.TAKE_AWAY];
	active: any = {};
	loading$ = new BehaviorSubject(false);
	public relevantVenues: Venue[] = [];
	isVenueOpen = isVenueOpen;

	acceptsDelivery = false;
	firstDeliveryTime: string = null;
	acceptsInside = false;
	firstInsideTime: string = null;
	acceptsTakeaway = false;
	firstTakeAwayTime: string = null;

	constructor(
		protected repository: RepositoryService,
		public translate: TranslateService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private router: Router,
		private navService: NavigationService
	) {
		super(repository);
		this.loading$.next(false);
	}

	static navigate(router: Router) {
		return router.navigateByUrl(SelectVenuePage.url);
	}

	ngOnInit() {
		super.ngOnInit();

		this.route.params.subscribe((params: any) => {
			if (params && params?.shortId) {
				this.loadSelectedVenueWithTableId(params?.shortId);
			} else if (params && params?.shortId) {
				this.loadSelectedVenue(params?.shortId);
			} else {
				this.locateAndShowStores(PreorderType.INSIDE);
			}
		});
	}
	async loadSelectedVenueWithTableId(venueId: string) {
		try {
			this.loading$.next(true);
			const venue = await this.repository.getVenue(venueId);
			// const table = (await Api.getTable(this.venue._id, tableId)).data;
			const isOpenResturant = TimeUtils.doesHoursMatchNow(venue?.openingHours);
			if (!isOpenResturant) {
				this.loading$.next(false);
				this.snackbarCtrl.open(this.translate.instant('home_page.venue_closed'), null, {
					duration: 5000,
				});
				return;
			}
			console.log('venue : ', venue);

			if (venue) {
				this.relevantVenues = [venue];
				this.repository.venue.emit(venue);
				console.log('this.order?.preorder?.type : ', this.order?.preorder?.type);
				this.repository.createOrder(this.venue, this.address, OrderType.PREORDER, this.order?.preorder?.type);
				// this.order.tableNumber = table.number;
				// this.order.table = table._id;
				// this.repository.order.emit(this.order);
				sleep(200);
				// const categorySelected = await SelectCategoryComponent.show(this.modalCtrl, venue, this.order);
				this.loading$.next(false);

				// if (categorySelected.mainCategory) {
				// 	await this.navigationService.navigateToUrlWithParams(NAVIGATIONURLS.menu(), {
				// 		categoryId: categorySelected.mainCategory
				// 	});
				// }
				// select-venue/63170f9877f34e0018e4c844/1
				await MenuPage.navigate(this.router);
			}
			// await this.selectVenue(venue);
		} catch (e) {
			this.loading$.next(false);
		}
	}
	async loadSelectedVenue(venueId: string) {
		try {
			console.log(venueId);

			this.loading$.next(true);
			const venue = await this.repository.getVenue(venueId);
			if (venue) {
				this.relevantVenues = [venue];
			}
			console.log(this.relevantVenues);
		} catch (error) {
			console.log('loadSelectedVenue has error!!!!');
			await this.locateAndShowStores(PreorderType.INSIDE);
		}
		this.loading$.next(false);
	}
	ionViewDidEnter() {
		if (history.state.navigationId === 1) {
			this.repository.onOrderChange(null);
		}
		this.repository.verifiedOrder.emit(null);
	}

	async locateAndShowStores(preorderType: PreorderType) {
		this.loading$.next(true);
		const venues = await this.repository.getAllVenues(environment.customerGroup);
		if (venues.length > 0) {
			this.relevantVenues = venues;
		}
		this.loading$.next(false);
	}

	async loadVenueAndCreateOrder(venue: Venue, address: Address, preorderType: PreorderType) {
		const loadedVenue = await this.repository.getVenue(venue._id);
		this.repository.createOrder(loadedVenue, address, null, preorderType);
		// await this.navigationService.menu();
		this.loading$.next(false);
	}

	loadVenues() {
		for (const preorderType of this.preorderTypes) {
			this.active[preorderType] = false;
		}
		new Promise<void>(async (resolve, reject) => {
			try {
				const venues = await this.repository.getAllVenues(environment.customerGroup);
				for (const venue of venues) {
					for (const preorderType of this.preorderTypes) {
						this.active[preorderType] = this.active[preorderType] || venueAcceptsOrders(venue, preorderType);
					}
				}
				resolve();
			} catch (e) {
				reject(e);
			}
		}).finally(() => this.loading$.next(false));
	}
	async goToMenu(type: PreorderType) {
		this.locateAndShowStores(type);
	}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}

	goBack() {
		// this.navigationService.navigateToUrlWithParams(NAVIGATIONURLS.home(), null);
	}
	isVenueOpenFunc(venue: Venue) {
		return this.isVenueOpen(venue) ? this.translate.instant('venue_suggestion.open') : this.translate.instant('venue_suggestion.close');
	}

	async openInfoPage(venue: Venue) {
		await WorkingHoursModalComponent.show(this.modalCtrl, venue, PreorderType.INSIDE);
	}

	accepts(type: PreorderType): boolean {
		switch (type) {
			case PreorderType.DELIVERY:
				return this.acceptsDelivery;
			case PreorderType.TAKE_AWAY:
				return this.acceptsTakeaway;
			case PreorderType.INSIDE:
				return this.acceptsInside;
			default:
				return false;
		}
	}

	async selectVenue(venue: Venue, type: PreorderType) {
		this.loading$.next(true);
		const isOpenResturant = TimeUtils.doesHoursMatchNow(venue?.openingHours);
		if (!isOpenResturant && type !== PreorderType.TAKE_AWAY) {
			this.loading$.next(false);
			this.snackbarCtrl.open(this.translate.instant('home_page.venue_closed'), null, {
				duration: 5000,
			});
			return;
		}
		const loadedVenue = await this.repository.getVenue(venue._id);
		await sleep(100);
		if (!loadedVenue) {
			this.loading$.next(false);
			this.snackbarCtrl.open(this.translate.instant('home_page.venue_closed'), null, {
				duration: 5000,
			});
			return;
		}
		this.repository.createOrder(loadedVenue, this.address, OrderType.PREORDER, type);
		sleep(1000);
		await MenuPage.navigate(this.router);

		// if (type === PreorderType.INSIDE) {
		// 	const table = await SelectTableListComponent.show(
		// 		this.modalCtrl,
		// 		loadedVenue,
		// 		this.order
		// 	);
		// 	if (table && table?._id) {
		// 		this.order.tableNumber = table.number;
		// 		this.order.table = table._id;
		// 		this.repository.order.emit(this.order);
		// 		this.loading$.next(false);
		// 		await MenuPage.navigate(this.router);
		// 	} else {
		// 		this.loading$.next(false);
		// 	}
		// } else {
		// 	this.loading$.next(false);
		// }

		// if (type === PreorderType.TAKE_AWAY) {

		// }
	}

	goHome() {
		this.navService.home();
	}

	navigateToMap(venue: Venue) {
		if (!venue) {
			return;
		}
		window.open(
			'https://maps.google.de/maps?q=' + venue.street + ' ' + venue.number + ', ' + venue.postalCode + ' ' + venue.city.de,
			'_blank'
		);
	}
}
