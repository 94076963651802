// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-header {
  padding: 0px 16px;
  box-shadow: none;
}

.header-wrap {
  padding: 0px 20px;
  margin: 10px 0 0;
}

.name {
  margin-bottom: 19px;
  text-transform: uppercase;
}

.content {
  font-size: 14px;
  padding: 0 20px 20px;
}
.content p,
.content li,
.content ol,
.content h1,
.content h2,
.content h3,
.content h4 {
  font-size: 14px !important;
  color: black !important;
}
.content h3 {
  margin-top: 24px;
}

div {
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/about-us/about-us.page.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;EACA,gBAAA;AACD;;AAEA;EACE,iBAAA;EACD,gBAAA;AACD;;AACA;EACE,mBAAA;EACA,yBAAA;AAEF;;AAAA;EACC,eAAA;EACA,oBAAA;AAGD;AADC;;;;;;;EAOC,0BAAA;EACA,uBAAA;AAGF;AAAE;EACE,gBAAA;AAEJ;;AAEA;EACC,YAAA;AACD","sourcesContent":["ion-header {\n\tpadding: 0px 16px;\n\tbox-shadow: none;\n}\n\n.header-wrap {\n  padding: 0px 20px;\n\tmargin: 10px 0 0;\n}\n.name {\n  margin-bottom: 19px;\n  text-transform: uppercase;\n}\n.content {\n\tfont-size: 14px;\n\tpadding: 0 20px 20px;\n\n\tp,\n\tli,\n\tol,\n\th1,\n\th2,\n\th3,\n\th4 {\n\t\tfont-size: 14px !important;\n\t\tcolor: black !important;\n\t}\n\n  h3 {\n    margin-top: 24px;\n  }\n}\n\ndiv {\n\tcolor: black;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
