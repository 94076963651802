// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  background: white;
  padding: 13px 0;
}
.card ion-row {
  align-items: center;
}

.icon-col {
  padding: 0;
}
.icon-col ion-row ion-img {
  height: 24px;
  width: 39px;
  margin: 0 4px;
}
.icon-col ion-row :last-child,
.icon-col ion-row :first-child {
  margin: 0;
}

.title {
  font-family: AppFontBold;
  font-size: 12px;
  margin: 0 4px;
  font-weight: 700;
}

.image-payment:not(:last-child) {
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/payment-methods-display/payment-methods-display.component.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;EACA,eAAA;AACD;AAEC;EACC,mBAAA;AAAF;;AAIA;EACC,UAAA;AADD;AAME;EACC,YAAA;EACA,WAAA;EACA,aAAA;AAJH;AAOE;;EAEC,SAAA;AALH;;AAUA;EACC,wBAAA;EACA,eAAA;EACA,aAAA;EACA,gBAAA;AAPD;;AAUA;EACC,iBAAA;AAPD","sourcesContent":[".card {\n\tbackground: white;\n\tpadding: 13px 0;\n\t// margin: 10px 0 10px;\n\n\tion-row {\n\t\talign-items: center;\n\t}\n}\n\n.icon-col {\n\tpadding: 0;\n\n\tion-row {\n\t\t// flex-direction: row-reverse;\n\n\t\tion-img {\n\t\t\theight: 24px;\n\t\t\twidth: 39px;\n\t\t\tmargin: 0 4px;\n\t\t}\n\n\t\t:last-child,\n\t\t:first-child {\n\t\t\tmargin: 0;\n\t\t}\n\t}\n}\n\n.title {\n\tfont-family: AppFontBold;\n\tfont-size: 12px;\n\tmargin: 0 4px;\n\tfont-weight: 700;\n}\n\n.image-payment:not(:last-child) {\n\tmargin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
