import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { AnalyticsService } from '../smoothr-web-app-core/services/analytics/analytics.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Api } from '../smoothr-web-app-core/api/api';

// import { SwUpdate } from '@angular/service-worker';
import { NavigationService } from './services/navigation.service';
import { filter } from 'rxjs/operators';

declare var cookieConsentData: any;
declare var cookieConsentCallback: any;
import { register } from 'swiper/element/bundle';
register();

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
	static largeScreen = false;
	static analyticsEnabled = false;
	isCookieAccepted = false;

	constructor(
		private route: ActivatedRoute,
		private alertCtrl: AlertController,
		private snackbarCtrl: MatSnackBar,
		private router: Router,
		private analytics: AnalyticsService,
		platform: Platform,
		private translate: TranslateService,
		// private swUpdate: SwUpdate,
		private navigationService: NavigationService,
		private modalCtrl: ModalController
	) {
		console.log(environment.customerGroup + ' ' + environment.version);
		const favIcon: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
		favIcon.type = 'image/x-icon';
		favIcon.rel = 'shortcut icon';
		favIcon.href = '/assets/the-cup/logo-black.svg';
		document.getElementsByTagName('head')[0].appendChild(favIcon);
		document.title = translate.instant('index.title.' + environment.customerGroup);
		translate.setDefaultLang('de');
		translate.use('de');

		this.initCookieConsent();

		// swUpdate.available.subscribe(() => {

		// });
		// if (swUpdate.isEnabled) {
		// 	console.log('Checking for App update');
		// 	swUpdate.checkForUpdate().finally(() => console.log('Update checking finished'));
		// } else {
		// 	swUpdate.activateUpdate().finally(async () => {
		// 		console.log('Updates activated result: ' + swUpdate.isEnabled);
		// 		if (swUpdate.isEnabled) {
		// 			console.log('Checking for App update');
		// 			await swUpdate.checkForUpdate();
		// 		} else {
		// 			console.log('SwUpdate is disabled (no service worker)');
		// 		}
		// 	});
		// }
		platform.ready().then(() => {
			AppComponent.largeScreen = platform.width() >= 992;
			platform.resize.subscribe(() => {
				AppComponent.largeScreen = platform.width() >= 992;
			});
		});
		Api.isMaintenanceActive()
			.then(result => {
				if (result) {
					this.navigationService.maintenance();
				}
			})
			.catch(error => {
				console.log(error);
			});
	}

	ngOnInit() {}

	ngDoCheck() {}

	async handleCookieConsent() {
		console.log('handleCookieConsent()');
		AppComponent.analyticsEnabled = cookieConsentData.targeting;
		await this.setAnalyticsEnabled(cookieConsentData.targeting);
	}

	async setAnalyticsEnabled(enabled: boolean) {
		await this.analytics.setAnalyticsCollectionEnabled(enabled);
	}

	private async initCookieConsent() {
		const onContentLoaded = async () => {
			cookieConsent.run({
				notice_banner_type: 'simple',
				consent_type: 'express',
				palette: 'dark',
				language: 'de',
				website_name: this.translate.instant('cookie_consent.title.' + environment.customerGroup[1]),
				cookies_policy_url: this.translate.instant('cookie_consent.policy_url.' + environment.customerGroup[1]),
			});
		};
		if (document.readyState === 'loading') {
			document.addEventListener('DOMContentLoaded', onContentLoaded);
		} else {
			await onContentLoaded();
		}
		cookieConsentCallback = async () => {
			console.log(cookieConsentData);

			await this.handleCookieConsent();
		};
		await this.handleCookieConsent();
	}

	// async iframe() {
	// 	await CookieInfoModalComponent.show(this.modalCtrl, {
	// 		info: this.translate.instant('not_accept_cookie'),

	// 		closeButton: this.translate.instant('short_info.close'),
	// 	});
	// 	return;
	// }
}
