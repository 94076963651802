import { Component, EventEmitter, Input, Output } from '@angular/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import { environment } from '../../../environments/environment';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import TagUtils from '../../../smoothr-web-app-core/utils/tag-utils';
import { getPrice, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';
import { numberD } from 'src/smoothr-web-app-core/utils/utils';
import { AllergensInfoModalComponent } from '../allergens-info-modal/allergens-info-modal.component';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-item-selectable-article',
	templateUrl: './item-selectable-article.component.html',
	styleUrls: ['item-selectable-article.component.scss'],
})
export class ItemSelectableArticleComponent {
	@Input() article: Article;
	@Input() selected: number;
	@Input() preorderType: PreorderType;
	@Input() group: OptionGroup;
	@Output() add = new EventEmitter<Article>();
	@Output() remove = new EventEmitter();
	@Input() currency: string;
	numberToCurrency = numberToCurrency;

	constructor(private modalCtrl: ModalController) {}

	get price(): number {
		return getPrice(this.article, OrderType.PREORDER, this.preorderType);
	}

	get isEmptyArticle(): boolean {
		return TagUtils.hasEmptyTag(this.article);
	}

	isAllergens(article: Article) {
		console.log(article.name, article);
		if (article?.compound?.allergens) return Object.values(article?.compound?.allergens)?.find(it => it === true);
		else return null;
	}

	isAddit(article: Article) {
		if (article?.compound?.additives) return Object.values(article?.compound?.additives)?.find(it => it === true);
		else return null;
	}
	isHaveAnyValueMoreZero(article: any) {
		if (!article?.nutritions) {
			return null;
		}
		return Object.values(article?.nutritions)
			.map(it => (numberD(it) ? numberD(it) : 0))
			.find(it => it > 0);
	}
	async openInfoClick(option: Article) {
		await AllergensInfoModalComponent.show(this.modalCtrl, option);
	}
}
