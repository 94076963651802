import { Component } from '@angular/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { NavigationService } from 'src/app/services/navigation.service';
enum Language {
	GreatBritain = 'en',
	German = 'de',
}

@Component({
	selector: 'app-menu-modal',
	templateUrl: './menu-modal.component.html',
	styleUrls: ['./menu-modal.component.scss'],
})
export class MenuModalComponent extends RepositoryDirective {
	environment = environment;
	languageEnum = Language;

	constructor(
		public repository: RepositoryService,
		private router: Router,
		private modalController: ModalController,
		private snackbarCtrl: MatSnackBar,
		public translate: TranslateService,
		private navigationService: NavigationService
	) {
		super(repository);
	}

	async signIn() {
		try {
			await this.modalController.dismiss();
			await this.modalController.dismiss();
			await this.navigationService.signIn();
		} catch (e) {
			await this.navigationService.signIn();
		}
	}

	async signUp() {
		try {
			await this.modalController.dismiss();
			await this.modalController.dismiss();
			await this.navigationService.signUp();
		} catch (e) {
			await this.navigationService.signUp();
		}
	}

	async mySpace() {
		try {
			await this.modalController.dismiss();
			await this.modalController.dismiss();
			await this.navigationService.account();
		} catch (e) {
			await this.navigationService.account();
		}
	}

	async myOrders() {
		try {
			await this.modalController.dismiss();
			await this.modalController.dismiss();

			await this.navigationService.myOrders();
		} catch (e) {
			await this.navigationService.myOrders();
		}
	}

	async allergen() {
		try {
			await this.modalController.dismiss();
			await this.modalController.dismiss();

			await this.navigationService.allergen();
		} catch (e) {
			await this.navigationService.allergen();
		}
	}

	async logout() {
		try {
			this.repository.customerAuth.emit(null);
			this.snackbarCtrl.open(this.translate.instant('menu_popover.logout_success'), null, {
				duration: 2000,
			});
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('menu_popover.logout_error'), null, {
				duration: 2000,
			});
		}
		await this.modalController.dismiss();
	}

	async home() {
		await this.modalController.dismiss();
		await this.navigationService.home();
	}

	async aboutUs() {
		try {
			await this.modalController.dismiss();
			await this.modalController.dismiss();
			await this.navigationService.aboutUs();
		} catch (e) {
			await this.navigationService.aboutUs();
		}
	}

	async gallery() {
		try {
			await this.modalController.dismiss();
			await this.modalController.dismiss();
			await this.navigationService.gallery();
		} catch (e) {
			await this.navigationService.gallery();
		}
	}

	async privacy() {
		try {
			await this.modalController.dismiss();
			await this.modalController.dismiss();
			await this.navigationService.privacy();
		} catch (e) {
			await this.navigationService.privacy();
		}
	}

	async tos() {
		await this.modalController.dismiss();
		try {
			await this.modalController.dismiss();
			await this.modalController.dismiss();
			await this.navigationService.tos();
		} catch (e) {
			await this.navigationService.tos();
		}
	}

	async impressum() {
		try {
			await this.modalController.dismiss();
			await this.modalController.dismiss();
			await this.navigationService.imprint();
		} catch (e) {
			await this.navigationService.imprint();
		}
	}

	// async store() {
	// 	await WorkingHoursModalComponent.show(this.modalCtrl, this.venue, PreorderType.INSIDE);
	// }
	async allergens() {
		await this.modalController.dismiss();
		// TODO open PDF
	}

	dismiss() {
		this.modalController.dismiss();
	}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
}
