// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  background: white;
  border-radius: 50px;
  padding: 3px 7px;
}
.chip p {
  font-size: 11px;
  color: white;
}
.chip .table {
  margin-left: 5px;
}
.chip .table_text {
  font-family: AppFontBold, sans-serif;
  margin: 0 8px;
}
.chip .number {
  padding-left: 10px;
}
.chip ion-icon {
  height: 20px;
  width: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/chip-table/chip-table.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,iBAAA;EACA,iBAAA;EACA,mBAAA;EAEA,gBAAA;AAAJ;AACI;EACI,eAAA;EACA,YAAA;AACR;AACI;EACI,gBAAA;AACR;AACI;EACI,oCAAA;EACA,aAAA;AACR;AACI;EACI,kBAAA;AACR;AACI;EACG,YAAA;EACA,WAAA;AACP","sourcesContent":[".chip{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    flex-wrap: nowrap;\n    background: white;\n    border-radius: 50px;\n    // height: 26px;\n    padding: 3px 7px;\n    p {\n        font-size: 11px;\n        color: white;\n    }\n    .table{\n        margin-left: 5px;     \n    }\n    .table_text {\n        font-family: AppFontBold, sans-serif;\n        margin: 0 8px;\n    }\n    .number {\n        padding-left: 10px;\n    }\n    ion-icon {\n       height: 20px;\n       width: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
