import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HomePage } from '../home/home.page';
import { MenuPage } from '../menu/menu.page';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { Location } from '@angular/common';
import { MenuModalComponent } from '../../components/menu-modal/menu-modal.component';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-privacy',
	templateUrl: './privacy.page.html',
	styleUrls: ['privacy.page.scss'],
})
export class PrivacyPage {
	static url = 'privacy';
	constructor(
		private repository: RepositoryService,
		private modalCtrl: ModalController,
		private navigationService: NavigationService,
		private location: Location
	) {}

	async goBack() {
		this.modalCtrl.dismiss();
		this.presentPopover();
		this.close();
	}

	async close() {
		if (this.location) {
			this.location.back();

			return;
		}
		if (this.repository._order === null) {
			await this.navigationService.home();
		} else {
			await this.navigationService.menu();
		}
	}

	async presentPopover() {
		const popover = await this.modalCtrl.create({
			component: MenuModalComponent,
			backdropDismiss: true,
			showBackdrop: true,
			cssClass: 'menu-modal',
		});

		await popover.present();

		return;
	}
}
