// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
}

.info-section {
  display: flex;
}
.info-section .name {
  display: flex;
  align-items: center;
}
.info-section .name h3 {
  font-size: 14px;
  font-family: AppFontBold, sans-serif;
}
.info-section .name div {
  background: var(--ion-color-primary);
  padding: 3px;
  font-size: 8px;
  border-radius: 50px;
  color: white;
  margin-left: 10px;
}
.info-section .info {
  display: flex;
  align-items: center;
}
.info-section .info p {
  font-size: 12px;
  font-family: AppFont, sans-serif;
}
.info-section .info ion-icon {
  width: 15px;
  padding-left: 10px;
}

ion-icon {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/components/app-toolbar-venue-info/app-toolbar-venue-info.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AACA;EACI,aAAA;AAEJ;AAAI;EACI,aAAA;EACA,mBAAA;AAER;AAAQ;EACI,eAAA;EACA,oCAAA;AAEZ;AACQ;EACI,oCAAA;EACA,YAAA;EACA,cAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;AACZ;AAEI;EACI,aAAA;EACA,mBAAA;AAAR;AACQ;EACI,eAAA;EACA,gCAAA;AACZ;AACQ;EACI,WAAA;EACA,kBAAA;AACZ;;AAGA;EACI,eAAA;AAAJ","sourcesContent":[".wrapper {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background: transparent;\n}\n.info-section {\n    display: flex;\n\n    .name {\n        display: flex;\n        align-items: center;\n\n        h3 {\n            font-size: 14px;\n            font-family: AppFontBold, sans-serif;\n\n        }\n        div {\n            background: var(--ion-color-primary);\n            padding: 3px;\n            font-size: 8px;\n            border-radius: 50px;\n            color: white;\n            margin-left: 10px;\n        }\n    }\n    .info {\n        display: flex;\n        align-items: center;\n        p {\n            font-size: 12px;\n            font-family: AppFont, sans-serif;\n        }\n        ion-icon {\n            width: 15px;\n            padding-left: 10px;\n        }\n    }\n}\nion-icon {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
