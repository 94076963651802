import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-select-table-info-modal',
	templateUrl: './select-table-info-modal.component.html',
	styleUrls: ['./select-table-info-modal.component.scss'],
})
export class SelectTableInfoModalComponent implements OnInit {
	table: number;

	constructor(
		private modalCtrl: ModalController,
		public translate: TranslateService
	) {}

	static async show(modalCtrl: ModalController, table: any): Promise<any> {
		const modal = await modalCtrl.create({
			component: SelectTableInfoModalComponent,
			componentProps: {
				table,
			},
			cssClass: 'short-info-modal auto-height',
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		return (await modal.onDidDismiss()).data;
	}

	ngOnInit() {}

	async close() {
		await this.modalCtrl.dismiss();
	}
}
