import { Component, Input } from '@angular/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { environment } from 'src/environments/environment';
import { defaultsToArticleOption, getBasePrice, getPrice, numberD, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';
import { AllergensInfoModalComponent } from '../allergens-info-modal/allergens-info-modal.component';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-item-view',
	templateUrl: './item-view.component.html',
	styleUrls: ['item-view.component.scss'],
})
export class ItemViewComponent {
	@Input() currency: string;
	price: number;
	ot = OrderType;
	environment = environment;
	@Input() hasOrder = false;
	priceText = '';
	numberToCurrency = numberToCurrency;

	constructor(private modalCtrl: ModalController) {}

	private _article: Article;

	get article(): Article {
		return this._article;
	}

	@Input()
	set article(value: Article) {
		this._article = value;
		this.reloadPrice();
	}

	private _preorderType: PreorderType = null;

	get preorderType(): PreorderType {
		return this._preorderType;
	}

	@Input()
	set preorderType(value: PreorderType) {
		this._preorderType = value;
		this.reloadPrice();
	}

	private reloadPrice() {
		if (this.article && this.preorderType) {
			const articleGroup = new ArticleGroup();
			articleGroup.article = this.article;
			articleGroup.groups = defaultsToArticleOption(this.article, [], this.article.defaults, OrderType.PREORDER, this.preorderType);
			articleGroup.quantity = 1;
			this.price = OrderUtils.articleGroupsTotalPrice([articleGroup], OrderType.PREORDER, this.preorderType);
		} else {
			this.price = 0;
		}
		this.priceText = numberToCurrency(this.price, this.currency);
	}

	async openInfoClick(option: Article) {
		await AllergensInfoModalComponent.show(this.modalCtrl, option);
	}

	isAllergens(article: Article) {
		if (article?.compound?.allergens) return Object.values(article?.compound?.allergens)?.find(it => it === true);
		else return null;
	}

	isAddit(article: Article) {
		if (article?.compound?.additives) return Object.values(article?.compound?.additives)?.find(it => it === true);
		else return null;
	}
	isHaveAnyValueMoreZero(article: any) {
		if (!article?.nutritions) {
			return null;
		}
		return Object.values(article?.nutritions)
			.map(it => (numberD(it) ? numberD(it) : 0))
			.find(it => it > 0);
	}
	getArticleTags() {
		return Object.entries(this.article?.compound?.features || {})
			.filter(value => value[1] === true)
			.map(it => it[0])
			.filter(it => it === 'vegan' || it === 'vegetarian');
	}
	showMeasurement(article: Article) {
		if (article) {
			if (article?.measurement?.refAmount && article?.measurement?.refUnit && article.measurement.amount && this._preorderType) {
				if (article?.measurement?.refAmount == 100 && article?.measurement?.refUnit === 'g') {
					return (
						'1' +
						' ' +
						'kg' +
						' = ' +
						numberToCurrency(getBasePrice(article, OrderType.PREORDER, this._preorderType) * 10, this.currency)
					);
				}
				if (article?.measurement?.refAmount == 100 && article?.measurement?.refUnit === 'ml') {
					return (
						'1' +
						' ' +
						'l' +
						' = ' +
						numberToCurrency(getBasePrice(article, OrderType.PREORDER, this._preorderType) * 10, this.currency)
					);
				}
				return (
					article.measurement.refAmount +
					' ' +
					article.measurement.refUnit +
					' = ' +
					numberToCurrency(getBasePrice(article, OrderType.PREORDER, this._preorderType), this.currency)
				);
			}
		} else {
			return '';
		}
	}
}
