// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-logo {
  width: 42px;
  height: 62px;
  margin-bottom: 26px;
}

.modal-container {
  padding: 36px 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-family: AppFontMedium;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: center;
  margin-bottom: 26px;
  color: #222222;
}
.title:first-child {
  margin-bottom: 10px;
}

.value {
  font-family: AppFontBold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #222222;
}

.button {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  --background: var(--ion-color-primary);
  border: 2px solid #F5B9C8;
  --border-radius: 0;
  margin-bottom: 15px;
  font-family: AppFontBold;
  font-size: 16px;
  color: #222222;
}
.button:last-child {
  --background: transparent;
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/restaurant-selection-modal/restaurant-selection-modal.component.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,YAAA;EACA,mBAAA;AACD;;AAEA;EACC,uBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACD;;AAEA;EACC,0BAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACC,kBAAA;EAED,mBAAA;EACC,cAAA;AAAF;AAEC;EACC,mBAAA;AAAF;;AAIA;EACC,wBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACC,cAAA;AADF;;AAKA;EACC,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sCAAA;EACC,yBAAA;EACA,kBAAA;EACA,mBAAA;EAED,wBAAA;EACA,eAAA;EACC,cAAA;AAHF;AAKE;EACE,yBAAA;EACA,gBAAA;AAHJ","sourcesContent":[".modal-logo {\n\twidth: 42px;\n\theight: 62px;\n\tmargin-bottom: 26px;\n}\n\n.modal-container {\n\tpadding: 36px 16px 24px;\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n}\n\n.title {\n\tfont-family: AppFontMedium;\n\tfont-size: 12px;\n\tline-height: 18px;\n\tletter-spacing: 0.01em;\n  text-align: center;\n\n\tmargin-bottom: 26px;\n  color: #222222;\n\n\t&:first-child {\n\t\tmargin-bottom: 10px;\n\t}\n}\n\n.value {\n\tfont-family: AppFontBold;\n\tfont-size: 16px;\n\tline-height: 24px;\n\tletter-spacing: 0.01em;\n  color: #222222;\n\n}\n\n.button {\n\twidth: 100%;\n\theight: 56px;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\t--background: var(--ion-color-primary);\n  border: 2px solid #F5B9C8;\n  --border-radius: 0;\n  margin-bottom: 15px;\n\n\tfont-family: AppFontBold;\n\tfont-size: 16px;\n  color: #222222;\n\n  &:last-child {\n    --background: transparent;\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
