import { Component } from '@angular/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { Location } from '@angular/common';
import { AppComponent } from 'src/app/app.component';
import { MenuModalComponent } from '../../components/menu-modal/menu-modal.component';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-gallery',
	templateUrl: './gallery.page.html',
	styleUrls: ['gallery.page.scss'],
})
export class GalleryPage {
	static url = 'gallery';
	largeScreen = AppComponent.largeScreen;
	gallery = Array.from({ length: 15 }, (_, i) => i + 1);
	structuredGallery = this.groupArray(this.gallery, this.largeScreen ? 6 : 4);

	constructor(
		private repository: RepositoryService,
		private navigationService: NavigationService,
		private location: Location,
		private modalCtrl: ModalController
	) {}

	async goBack() {
		this.modalCtrl.dismiss();
		this.presentPopover();
		this.close();
	}

	async close() {
		if (this.location) {
			this.location.back();

			return;
		}
		if (this.repository._order === null) {
			await this.navigationService.home();
		} else {
			await this.navigationService.menu();
		}
	}

	async presentPopover() {
		const popover = await this.modalCtrl.create({
			component: MenuModalComponent,
			backdropDismiss: true,
			showBackdrop: true,
			cssClass: 'menu-modal',
		});

		await popover.present();

		return;
	}

	groupArray(arr, groupSize: number): (number | string)[][] {
		const groupedArray = [];
		for (let i = 0; i < arr.length; i += groupSize) {
			groupedArray.push(arr.slice(i, i + groupSize));
		}
		return groupedArray;
	}

	getImgIndex(x: number, y: number) {
		const groupSize = this.largeScreen ? 6 : 4;
		return x * groupSize + y;
	}
}
