// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-selectable-article {
  margin: 0 auto;
  flex-grow: 1;
  min-height: 100%;
  word-break: break-word;
  background: white;
  border: 2px solid white;
  border-radius: 5px;
  transition: border 200ms linear;
  text-align: center;
}
.item-selectable-article.selected {
  border: 2px solid var(--ion-color-primary);
}

.option-price {
  font-size: 12px;
  margin: auto;
  font-weight: bold;
  color: black;
}

.name {
  margin-top: 12px;
  font-size: 14px;
  width: 100%;
  font-weight: bold;
}

.item-selectable-article-image {
  margin: 0 auto;
  height: 100px;
}

app-quantity-selector {
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
}

.quantity-row {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  --quantity-selector-border-width: 1.5px;
  --quantity-selector-height: 24px;
}

.info-block {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.pfand {
  color: black;
  font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/item-selectable-article/item-selectable-article.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,YAAA;EACA,gBAAA;EACA,sBAAA;EACA,iBAAA;EACA,uBAAA;EACA,kBAAA;EACA,+BAAA;EACA,kBAAA;AACF;AAAE;EACE,0CAAA;AAEJ;;AAEA;EACE,eAAA;EACA,YAAA;EACA,iBAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,WAAA;EACA,iBAAA;AACF;;AAEA;EACE,cAAA;EACA,aAAA;AACF;;AAEA;EACE,oDAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,OAAA;EACA,uBAAA;EACA,uCAAA;EACA,gCAAA;AACF;;AACA;EACC,qBAAA;EACA,kBAAA;EACA,sBAAA;AAED;;AAAA;EACC,YAAA;EACA,eAAA;AAGD","sourcesContent":[".item-selectable-article {\n  margin: 0 auto;\n  flex-grow: 1;\n  min-height: 100%;\n  word-break: break-word;\n  background: white;\n  border: 2px solid white;\n  border-radius: 5px;\n  transition: border 200ms linear;\n  text-align: center;\n  &.selected {\n    border: 2px solid var(--ion-color-primary);\n  }\n}\n\n.option-price {\n  font-size: 12px;\n  margin: auto;\n  font-weight: bold;\n  color: black;\n}\n\n.name {\n  margin-top: 12px;\n  font-size: 14px;\n  width: 100%;\n  font-weight: bold;\n}\n\n.item-selectable-article-image {\n  margin: 0 auto;\n  height: 100px;\n}\n\napp-quantity-selector {\n  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));\n}\n\n.quantity-row {\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  justify-content: center;\n  --quantity-selector-border-width: 1.5px;\n  --quantity-selector-height: 24px;\n}\n.info-block {\n\tdisplay: inline-block;\n\ttext-align: center;\n\tvertical-align: middle;\n}\n.pfand {\n\tcolor: black;\n\tfont-size: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
