// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-to-order-button {
  margin: 12px;
}

p {
  font-size: 16px;
  line-height: 20px;
  margin: 20px;
  text-align: center;
  color: white;
  font-family: AppFontBold, sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/app/components/to-order-button/to-order-button.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AACA;EACE,eAAA;EACA,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,oCAAA;AAEF","sourcesContent":[".add-to-order-button {\n  margin: 12px;\n}\np {\n  font-size: 16px;\n  line-height: 20px;\n  margin:20px;\n  text-align: center;\n  color: white;\n  font-family: AppFontBold, sans-serif;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
