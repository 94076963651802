// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-header {
  padding: 0px 16px;
  box-shadow: none;
}

ion-content {
  --padding-start: 20px;
  --padding-end: 20px;
}

.header-wrap {
  margin: 10px 0 0;
}

.name {
  margin-bottom: 19px;
  text-transform: uppercase;
}

ol {
  counter-reset: item;
  padding: 0;
}

p, li, ol, h3, h4 {
  color: black !important;
}

div {
  color: black;
}

ol > li {
  display: block;
}
ol > li h3:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
  margin-right: 6px;
}
ol > li p {
  display: block;
  margin: 0;
}
ol > li ol > li {
  margin: 8px 0;
  display: flex;
  flex-direction: row;
}

ol > li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
  margin: 0 8px 0 0;
}

ion-content > ol {
  padding: 0;
}

ion-content > ol > li:before {
  content: none;
}

ul {
  list-style-type: disc;
  padding-left: 30px;
}

ion-content > ol > li > div {
  padding-left: 30px;
}

p.ion-padding-start {
  padding-left: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/tos/tos.page.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;EACA,gBAAA;AACD;;AAEA;EACE,qBAAA;EACA,mBAAA;AACF;;AACA;EACC,gBAAA;AAED;;AACA;EACE,mBAAA;EACA,yBAAA;AAEF;;AACA;EACE,mBAAA;EACA,UAAA;AAEF;;AAAA;EACE,uBAAA;AAGF;;AAEA;EACE,YAAA;AACF;;AACA;EACE,cAAA;AAEF;AADE;EACE,iCAAA;EACA,uBAAA;EACA,iBAAA;AAGJ;AACE;EACE,cAAA;EACA,SAAA;AACJ;AAEE;EACE,aAAA;EACA,aAAA;EACA,mBAAA;AAAJ;;AAKA;EACE,gCAAA;EACA,uBAAA;EACA,iBAAA;AAFF;;AAKA;EACE,UAAA;AAFF;;AAKA;EACE,aAAA;AAFF;;AAKA;EACE,qBAAA;EACA,kBAAA;AAFF;;AAKA;EACE,kBAAA;AAFF;;AAKA;EACE,kBAAA;AAFF","sourcesContent":["ion-header {\n\tpadding: 0px 16px;\n\tbox-shadow: none;\n}\n\nion-content {\n  --padding-start: 20px;\n  --padding-end: 20px;\n}\n.header-wrap {\n\tmargin: 10px 0 0;\n}\n\n.name {\n  margin-bottom: 19px;\n  text-transform: uppercase;\n}\n\nol {\n  counter-reset: item;\n  padding: 0;\n}\np,li,ol,h3,h4 {\n  color: black !important;\n\n}\n\n\ndiv {\n  color: black;\n}\nol > li {\n  display: block;\n  h3:before {\n    content: counters(item, \".\") \". \";\n    counter-increment: item;\n    margin-right: 6px;\n\n  }\n\n  p {\n    display: block;\n    margin: 0;\n  }\n\n  ol > li {\n    margin: 8px 0;\n    display: flex;\n    flex-direction: row;\n\n  }\n}\n\nol > li:before {\n  content: counters(item, \".\") \" \";\n  counter-increment: item;\n  margin: 0 8px 0 0;\n}\n\nion-content > ol {\n  padding: 0;\n}\n\nion-content > ol > li:before {\n  content: none;\n}\n\nul {\n  list-style-type: disc;\n  padding-left: 30px;\n}\n\nion-content > ol > li > div {\n  padding-left: 30px;\n}\n\np.ion-padding-start {\n  padding-left: 30px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
